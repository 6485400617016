<!-- ||Mobile Nav Section End|| -->
<!-- Header Section Start -->
<header class="privacy-header">

  <section id="banner" class="psivacy-banner">
    <div class="container text-center">
      <h1>Frequently Asked Questions</h1>
    </div>
  </section>
  <div class="overlay-btn">
    <a routerLink="/home">Home </a>/
    <a routerLink="/faq">Frequently Asked Questions</a>
  </div>
</header>
<!-- ||Header Section End|| -->

<!-- Privacy Policy Section Start -->
<div id="faq">
  <div class="container">
    <div
      class="accordion accordion-flush"
      id="accordionFlushExample"
    >
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Q1. What services does Apprentice Valley Digital offer?
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            We offer a range of digital marketing services,
            and we’re actively involved in the Telecommunications,
            Mobile and Financial industries.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo"
          >
            Q2. What are the benefits of working with Apprentice Valley Digital?
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            By working with us, you'll gain access to expert knowledge,
            cutting-edge tools, and a supportive community to help you achieve your goals.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree"
            aria-expanded="false"
            aria-controls="flush-collapseThree"
          >
            Q3. What kind of support does Apprentice Valley Digital offer to its employees?
          </button>
        </h2>
        <div
          id="flush-collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            We offer ongoing support to our employees, including mentorship, coaching, and
            access to our community of experts.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFour">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour"
            aria-expanded="false"
            aria-controls="flush-collapseFour"
          >
            Q4. Who can benefit from Apprentice Valley Digital's services?
          </button>
        </h2>
        <div
          id="flush-collapseFour"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingFour"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            Our services are beneficial for businesses in various industries,
            including real estate, construction, manufacturing, and technology,
            that are looking to leverage IoT to generate high-quality leads.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFive">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFive"
            aria-expanded="false"
            aria-controls="flush-collapseFive"
          >
            Q5. How do you ensure the privacy and security of the data collected?
          </button>
        </h2>
        <div
          id="flush-collapseFive"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingFive"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            We take data privacy and security very seriously. We use advanced
            encryption and security protocols to protect the data collected from
            your IoT devices and mobile solutions. We also comply with relevant
            data protection regulations, such as GDPR and POPIA.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingSix">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSix"
            aria-expanded="false"
            aria-controls="flush-collapseSix"
          >
            Q6. How can I get started with Apprentice Valley Digital?
          </button>
        </h2>
        <div
          id="flush-collapseSix"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingSix"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            You can get started by filling out a contact form this website
            by sending an email. One of our friendly agents will reach out
            to discuss your needs and recommend the best solutions for you.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ||Privacy Policy Section End|| -->
